import {getWithdrawalPageListApi, updateWithdrawalPassOrRejectApi} from "@/api/WithdrawalApplicationApi";
import sd from "silly-datetime";

export const mixins = {
    data() {
        return {
            tableData: [],
            listParams: {
                current: 1,
                size: 10,
                name: "",
                telOrCardId: "",
                type: "",
                startTime: '',
                endTime: '',
                status: "",
            },
            tableDataTotal: 0,
            options: [
                {
                    value: '0',
                    label: '待处理'
                }, {
                    value: '1',
                    label: '已通过'
                }, {
                    value: '2',
                    label: '未通过'
                }],
            dateValue: "",
        }
    },
    methods: {
        async getWithdrawalPageList() {
            if (this.dateValue) {
                this.listParams.startTime = sd.format(this.dateValue[0], 'YYYY-MM-DD HH:mm:ss')
                this.listParams.endTime = sd.format(this.dateValue[1], 'YYYY-MM-DD HH:mm:ss')
            }

            let {data} = await getWithdrawalPageListApi(this.listParams)
            this.tableData = data.rows
            this.tableDataTotal = data.total
        },
        handleSizeChange(val) {
            this.listParams.size = val
            this.getWithdrawalPageList()
        },
        handleCurrentChange(val) {
            this.listParams.current = val
            this.getWithdrawalPageList()
        },
        clearParams() {
            this.listParams = {
                current: 1,
                size: 10,
                name: "",
                telOrCardId: "",
                type: "",
                startTime: '',
                endTime: '',
            }
        },
        async passOrReject(id, status) {
            await updateWithdrawalPassOrRejectApi({id, status})
            this.$message.success("修改成功")
            this.getWithdrawalPageList()
        }

    },
    created() {
        this.getWithdrawalPageList()
    }
}
