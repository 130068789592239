<template>
  <div>
    <TitleCard style="max-width: 97%; margin: 10px auto" title="提现管理">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">Withdrawal Management</li>
        </ul>
      </template>
    </TitleCard>

    <div id="kt_app_main" class="app-main flex-column flex-row-fluid">
      <div class="d-flex flex-column flex-column-fluid">
        <div id="kt_app_content" class="" style="margin-bottom: 10px;padding: 0px 30px;">
          <div class="card card-flush" style="position: relative">
            <div class="card-body">
              <div>
                <el-input v-model="listParams.name"
                          placeholder="提现姓名"
                          size="small"
                          clearable
                          style="width: 10%;margin-left: 5px"
                ></el-input>
                <el-input v-model="listParams.telOrCardId"
                          placeholder="手机号/银行卡号"
                          size="small"
                          clearable
                          style="width: 10%;margin-left: 5px"
                ></el-input>
                <el-select v-model="listParams.status"
                           placeholder="请选择状态"
                           size="small"
                           clearable
                           style="width: 10%;margin-left: 5px"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    clearable
                    v-model="dateValue"
                    end-placeholder="结束日期"
                    range-separator="至"
                    size="small"
                    start-placeholder="开始日期"
                    style="width: 23%;margin-left: 5px"
                    type="daterange"
                >
                </el-date-picker>
                <el-button round
                           size="small"
                           style="margin-left: 10px"
                           type="primary"
                           @click="getWithdrawalPageList"
                >查 询
                </el-button>
                <el-button round
                           size="small"
                           style="margin-left: 10px"
                           @click="clearParams"
                >重 置
                </el-button>
              </div>
              <el-table
                  :data="tableData"
                  style="width: 100%">
                <el-table-column
                    label="编号"
                    prop="id"
                    width="80">
                </el-table-column>
                <el-table-column
                    label="提现姓名"
                    prop="name"
                    width="100">
                </el-table-column>
                <el-table-column
                    label="手机号/银行卡号"
                    prop="telOrCardId">
                </el-table-column>
                <el-table-column
                    label="开户行"
                    prop="bankName">
                </el-table-column>
                <el-table-column
                    label="提现金额">
                  <template #default="scope">
                    {{ scope.row.price | format }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="状态">
                  <template #default="scope">
                    <el-tag v-if="scope.row.status === 0">待处理</el-tag>
                    <el-tag v-if="scope.row.status === 1" type="success">已通过</el-tag>
                    <el-tag v-if="scope.row.status === 2" type="danger">未通过</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                    label="提现时间"
                    prop="createTime">
                </el-table-column>
                <el-table-column
                    label="处理时间"
                    prop="updateTime">
                </el-table-column>
                <el-table-column
                    label="订单号"
                >
                  <template #default="scope">
                    <el-popover
                        placement="bottom"
                        trigger="click"
                        width="40%"
                    >
                      <div v-for="item in JSON.parse(scope.row.bindOrderNoJson)">
                        {{ item }}
                      </div>

                      <el-button slot="reference" round size="mini">查看信息</el-button>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                  <template #default="scope">
                    <el-button
                        v-if="scope.row.status == 0"
                        size="mini"
                        type="success"
                        @click="passOrReject(scope.row.id,1)"
                    >通过
                    </el-button>
                    <el-button
                        v-if="scope.row.status == 0"
                        size="mini"
                        type="danger"
                        @click="passOrReject(scope.row.id,2)"
                    >拒绝
                    </el-button>

                  </template>
                </el-table-column>
              </el-table>
              <div style="display: flex;justify-content: center;margin-top: 20px">
                <el-pagination
                    :current-page="listParams.current"
                    :page-size="listParams.size"
                    :page-sizes="[10, 20, 30, 40]"
                    :total="tableDataTotal"
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixins} from "@/views/withdrawalApplication/mixins";

export default {
  name: "withdrawalApplication",
  mixins: [mixins],
}
</script>

<style scoped>

</style>
