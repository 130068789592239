<template>
  <div>
    <TitleCard style="max-width: 97%; margin: 10px auto" title="商城管理">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">Mall Management</li>
        </ul>
      </template>
    </TitleCard>
    <div id="kt_post" class="post d-flex flex-column-fluid">
      <!--begin::Container-->
      <div id="kt_content_container" class="container" style="max-width:100%;">
        <div class="row g-5 g-xl-8">
          <div v-for="(item, index) in rows" :key="index" class="col-xl-3">
            <div class="template_wrap" style="background-color: white;padding:10px;border-radius:10px;">
              <!--begin::Mixed Widget 2-->
              <div class="template_msg">
                <div :id="'shopList_' + index" style="position:relative;">
                  <div v-if="code_show && code_id == 'shopList_' + index" class="code_wrap">
                    <div v-if="item.publication===2">
                      <div class="code_content" style="margin-top: -65px">
                        <img :src="qrCodeUrl" alt="" style="border-radius:inherit;width: 100%"/>
                      </div>
                      <div class="text_content">
                        <el-link class="copyUrl" data-clipboard-text="https://baijiuji.h5.zhelidao.com/"
                                 type="primary" @click="copyUrl">点击复制链接
                        </el-link>
                      </div>

                    </div>

                    <img v-else alt="" class="code_content"
                         src="https://www.3jzc.cn/Uploads/weixinerweima/zhelidao.jpg" style="top:50%"/>

                  </div>
                  <div style="display: flex; align-items: center;">
                    <div style="width: 40%">
                      <div class="thumbnailUrl">
                        <img :src="item.thumbnailUrl" alt="" style="width: 90%; height: auto;border-radius: 6px;">

                      </div>
                    </div>

                    <div style="width: 56%;">
                      <div style="display: flex;align-items: center;justify-content:space-between;padding-right:10px;">
                        <div
                            style="font-size: 17px;max-width:67%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-weight: 600;">
                          {{ item.name }}
                        </div>

                      </div>
                      <div style="font-size: 13px;margin-top: 8px;">创建时间：{{ item.crTime }}
                      </div>
                      <div style="font-size: 13px;margin-top: 4px;"> 
                      </div>
                      <div style="font-size: 13px;margin-top: 4px;display: flex;align-items: center;">
                         
                      </div>
                    </div>
                  </div>
                  <div class="data_wrap mt-4"
                       style="display: flex;justify-content:space-around;color:#071437;;">
                    <div class="data_item text-hover-primary" data-bs-target="#myShop_modal_users"
                         data-bs-toggle="modal" style="cursor:pointer;"
                         @click="openWxUserList">
                      <div style="font-size: 13px;">用户数量</div>
                      <div style="font-size: 16px; color: rgb(0, 158, 247);text-align: center;">{{
                          count
                        }}
                      </div>
                    </div>


                  </div>
                </div>

                <div style="display: flex; align-items: center;">
                  <div class="col cursor-pointer" style="cursor:pointer; border-right: 1px solid #ddd;"
                       @click="openQrcode('shopList_' + index, item.id)">
                                        <span class="svg-icon svg-icon-2hx svg-icon-primary d-block my-2 text-center">
                                            <svg class="bi bi-qr-code text-muted" fill="currentColor"
                                                 style="width: 20px; height: 20px !important;" viewBox="0.7 0 14 16"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 2h2v2H2V2Z"></path>
                                                <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z">
                                                </path>
                                                <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z">
                                                </path>
                                                <path
                                                    d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z">
                                                </path>
                                                <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z">
                                                </path>
                                            </svg> </span> <span
                      class="svg-icon svg-icon-3x svg-icon-primary d-block my-2 text-center">
                                            <a class="text-dark fs-6 text-hover-primary"
                                               href="javascript:void(0)">二维码</a></span>
                  </div>
                  <div class="col cursor-pointer" data-bs-target="#myShop_modal_Voucher"
                       data-bs-toggle="modal"
                       style="cursor:pointer; border-right: 1px solid #ddd;"
                       @click="openCard"> <span
                      class="svg-icon svg-icon-2hx svg-icon-primary d-block my-2 text-center">
                                            <svg class="bi bi-wallet-fill text-muted" fill="currentColor"
                                                 style="width: 20px; height: 20px !important;" viewBox="0.7 0 14 16"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z">
                                                </path>
                                                <path
                                                    d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z">
                                                </path>
                                            </svg> </span> <span
                      class="svg-icon svg-icon-3x svg-icon-primary d-block my-2 text-center">
                                            <a class="text-dark fs-6 text-hover-primary"
                                               href="javascript:void(0)">卡密</a>
                                        </span></div>
                  <div class="resize-triggers">
                    <div class="expand-trigger">
                      <div style="width: 404px; height: 459px;"></div>
                    </div>
                    <div class="contract-trigger"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height:20px"></div>
      </div>

    </div>

    <!-- 用户的弹窗 -->
    <el-dialog v-if="wxShopUserVisible" :close-on-click-modal="false" :visible.sync="wxShopUserVisible" title="商城用户"
               width="80%">
      <el-input v-model="wxUserParams.tel"
                placeholder="请输入要查询的手机号"
                size="small"
                style="width: 20%;margin-right: 20px;margin-left: 10px"
      >
      </el-input>
      <el-select v-model="wxUserParams.level"
                 placeholder="请选择等级"
                 size="small"
                 style="width: 20%;margin-right: 20px;margin-left: 10px">
        <el-option
            label="普通用户"
            value="1">
        </el-option>
        <el-option
            label="金牌会员"
            value="2">
        </el-option>
        <el-option
            label="唐玖集品牌大使"
            value="3">
        </el-option>
      </el-select>
      <el-button round
                 size="small"
                 style="margin-right: 5px"
                 type="primary"
                 @click="getWxShopUserList">搜索
      </el-button>
      <el-button round
                 size="small"
                 style="margin-right: 5px"
                 @click="resetParams">重置
      </el-button>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            label="编号"
            prop="id"
            width="80">
        </el-table-column>
        <el-table-column
            label="手机号"
            prop="tel"
            width="180">
        </el-table-column>
        <el-table-column
            label="积分"
            prop="score"
            width="100">
        </el-table-column>
        <el-table-column
            width="140"
            label="等级">
          <template slot-scope="scope">
            <el-tag
                v-if="scope.row.level === 1"
                disable-transitions
                type="info">普通用户
            </el-tag>
            <el-tag
                v-if="scope.row.level === 2"
                disable-transitions
                type="warning">金牌会员
            </el-tag>
            <el-tag
                v-if="scope.row.level === 3"
                disable-transitions
                type="success">唐玖集品牌大使
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="注册时间"
            prop="crTime"
            width="180">
        </el-table-column>
        <el-table-column
            label="累计佣金"
            prop="totalAmount"
            width="100">
        </el-table-column>
        <el-table-column
            label="可提现金额"
            prop="withdrawalAmount"
            width="100">
        </el-table-column>
        <el-table-column
            label="用户订单数"
            prop="orderCount"
            width="100">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.row)">修改积分
            </el-button>
            <el-button
                size="mini"
                type="primary"
                @click="checkScore(scope.row)">积分记录
            </el-button>
            <el-button
                size="mini"
                type="success"
                @click="editLevel(scope.row)">修改等级
            </el-button>
            <el-button
                size="mini"
                type="warning"
                @click="lookPromoter(scope.row)">查看推广人
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <el-pagination
            :current-page="wxUserParams.current"
            :page-size="wxUserParams.size"
            :page-sizes="[10, 20, 30, 40]"
            :total="tableDataTotal"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog v-if="wxShopCardVisible" :close-on-click-modal="false" :visible.sync="wxShopCardVisible" title="卡密管理"
               width="80%">
      <div style="display: flex;justify-content: space-between;">
        <div style="width: 80%">
          <el-input v-model="wxUserCardParams.cardId" placeholder="请输入要查询的卡号" size="small"
                    style="width: 15%;margin-right: 5px"></el-input>
          <el-input v-model="wxUserCardParams.cardPassword" placeholder="请输入要查询的卡密" size="small"
                    style="width: 15%;margin-right: 5px"></el-input>
          <el-input v-model="wxUserCardParams.tel" placeholder="请输入要查询的手机号" size="small"
                    style="width: 15%;margin-right: 5px"></el-input>
          <el-select v-model="wxUserCardParams.status" clearable placeholder="请选择状态"
                     size="small" style="width: 15%;margin-right: 5px">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button round size="small" type="primary" @click="getWxShopCardList">查询</el-button>
          <el-button round size="small" @click="resetWxShopCardList">重置</el-button>
        </div>
        <div>
          <el-button round size="small" type="primary" @click="openBatchUpdate">批量操作</el-button>
          <el-button round size="small" type="primary" @click="openGenerated">生成卡密</el-button>
        </div>
      </div>

      <el-table
          :data="tableCardData"
          style="width: 100%">
        <el-table-column
            label="卡号"
            prop="cardId"
            width="180">
        </el-table-column>
        <el-table-column
            label="卡密"
            prop="cardPassword"
            width="180">
        </el-table-column>
        <el-table-column
            label="积分"
            prop="score"
            width="180">
        </el-table-column>
        <el-table-column
            label="状态">
          <template slot-scope="scope">
            <el-tag
                v-if="scope.row.status === 1"
                disable-transitions>待激活
            </el-tag>
            <el-tag
                v-if="scope.row.status === 2"
                disable-transitions
                type="success">待使用
            </el-tag>
            <el-tag
                v-if="scope.row.status === 3"
                disable-transitions
                type="info">已使用
            </el-tag>
            <el-tag
                v-if="scope.row.status === 4"
                disable-transitions
                type="danger">作废
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            prop="createTime"
            width="180">
        </el-table-column>
        <el-table-column
            label="到期时间"
            prop="endTime"
            width="180">
        </el-table-column>
        <el-table-column
            label="使用人手机号"
            prop="tel"
            width="180">
        </el-table-column>
        <el-table-column
            label="使用时间"
            prop="useTime"
            width="180">
        </el-table-column>
        <el-table-column
            label="操作"
            width="180">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.status !== 2  && scope.row.status != 3"
                size="mini"
                type="primary"
                @click="updateCardStatusByOne(scope.row,2)">激活
            </el-button>
            <el-button
                v-if="scope.row.status != 1 && scope.row.status != 3 && scope.row.status != 4"
                size="mini"
                type="danger"
                @click="updateCardStatusByOne(scope.row,4)">作废
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <el-pagination
            :current-page="wxUserCardParams.current"
            :page-size="wxUserCardParams.size"
            :page-sizes="[10, 20, 30, 40]"
            :total="tableDataCardTotal"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChangeCard"
            @current-change="handleCurrentChangeCard">
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog v-if="wxShopCarGeneratedVisible" :close-on-click-modal="false" :visible.sync="wxShopCarGeneratedVisible" title="卡密生成"
               width="40%">
      <el-form ref="ruleForm" :model="cardRuleForm" class="demo-ruleForm" label-width="30%">
        <el-form-item label="生成数量" prop="num">
          <el-input-number v-model.number="cardRuleForm.num"
                           :max="30000"
                           :min="1"
                           style="width: 66%">

          </el-input-number>
        </el-form-item>
        <el-form-item label="卡号前缀" prop="cardNoPrefix">
          <el-input v-model="cardRuleForm.cardNoPrefix"
                    placeholder="请输入卡号前缀(可为空)"
                    style="width: 66%">

          </el-input>
        </el-form-item>
        <el-form-item label="积分" prop="score">
          <el-input-number v-model.number="cardRuleForm.score"
                           :min="1"
                           placeholder="请输入积分"
                           style="width: 66%">

          </el-input-number>
        </el-form-item>
        <el-form-item :rules="[
              { required: true, message: '卡密规则不能为空' }

          ]" label="卡密规则:" prop="itemRule">
          <el-select v-model="cardRuleForm.length" placeholder="请选择生成位数" style="width: 30%">
            <el-option label="6位" value="6"></el-option>
            <el-option label="7位" value="7"></el-option>
            <el-option label="8位" value="8"></el-option>
            <el-option label="9位" value="9"></el-option>
            <el-option label="10位" value="10"></el-option>
            <el-option label="11位" value="11"></el-option>
            <el-option label="12位" value="12"></el-option>
          </el-select>

          <el-select v-model="cardRuleForm.itemRule" placeholder="请选择生成规则" style="width: 36%">
            <el-option :value="1" label="纯数字">
              <span style="float: left">纯数字</span>
              <span style="float: right; color: #8492a6; font-size: 13px">简单</span>
            </el-option>
            <el-option :value="2" label="英文小写">
              <span style="float: left">英文小写</span>
              <span style="float: right; color: #8492a6; font-size: 13px">简单</span>
            </el-option>
            <el-option :value="3" label="英文大写">
              <span style="float: left">英文大写</span>
              <span style="float: right; color: #8492a6; font-size: 13px">简单</span>
            </el-option>
            <el-option :value="4" label="纯数字 + 英文小写">
              <span style="float: left">纯数字 + 英文小写</span>
              <span style="float: right; color: #8492a6; font-size: 13px">一般</span>
            </el-option>
            <el-option :value="5" label="纯数字 + 英文大写">
              <span style="float: left">纯数字 + 英文大写 </span>
              <span style="float: right; color: #8492a6; font-size: 13px">一般</span>
            </el-option>
            <el-option :value="6" label="纯数字 + 英文小大写">
              <span style="float: left">纯数字 + 英文小大写</span>
              <span style="float: right; color: #8492a6; font-size: 13px">复杂</span>
            </el-option>
          </el-select>
          <el-tooltip effect="dark" placement="bottom" style="margin-left: 5px">
            <div slot="content">
              增加卡密长度提高密码安全性
              <br/>
              数字和大小写英文不包含：0、1、2、9、i、l、o、z、g、q、I、L、O、Z
            </div>
            <i class="el-icon-question"/>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="到期时间" prop="endTime">
          <el-date-picker
              v-model="cardRuleForm.endTime"
              placeholder="选择日期时间"
              style="width: 66%"
              type="datetime">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitGeneratedForm()">立即创建</el-button>
          <el-button @click="resetGeneratedForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-if="wxShopCarBatchVisible" :close-on-click-modal="false" :visible.sync="wxShopCarBatchVisible" title="卡密批量操作"
               width="40%">
      <el-form ref="form" :model="formBatch" label-width="30%">
        <el-form-item label="起始卡号">
          <el-input v-model="formBatch.startCardId" placeholder="请输入起始卡号" style="width: 70%"></el-input>
        </el-form-item>
        <el-form-item label="结束卡号">
          <el-input v-model="formBatch.endCardId" placeholder="请输入结束卡号" style="width: 70%"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formBatch.status" placeholder="请选择状态" style="width: 70%">
            <el-option
                label="激活"
                value="2">
            </el-option>
            <el-option
                label="作废"
                value="4">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div style="display: flex;justify-content:center">
        <el-button type="primary" @click="updateCardStatusByBatch">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog v-if="wxShopUserScoreVisible" :close-on-click-modal="false" :visible.sync="wxShopUserScoreVisible"
               title="积分记录"
               width="40%">

      <el-table
          :data="wxUserScoreTableData"
          style="width: 100%">
        <el-table-column
            label="状态"
            width="180">
          <template slot-scope="scope">
            <p v-if="scope.row.type == 1">充值</p>
            <p v-if="scope.row.type == 2">手动调整</p>
            <p v-if="scope.row.type == 3">购物消耗</p>
            <p v-if="scope.row.type == 7">返还积分</p>
          </template>
        </el-table-column>
        <el-table-column
            label="积分"
            prop="score">
        </el-table-column>
        <el-table-column
            label="处理时间"
            prop="crTime">
        </el-table-column>
        <el-table-column
            label="关联订单"
            prop="orderId">
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <el-pagination
            :current-page="wxUserScoreParams.current"
            :page-size="wxUserScoreParams.size"
            :page-sizes="[10, 20, 30, 40]"
            :total="wxShopUserScoreTotal"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChangeScore"
            @current-change="handleCurrentChangeScore">
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog v-if="editLevelVisible" :close-on-click-modal="false" :visible.sync="editLevelVisible" title="修改等级"
               width="20%">
      <div style="display: flex;justify-content: center">
        修改等级将解绑之前的推广关系，请慎重操作。
      </div>
      <div style="display: flex;justify-content: center;margin-top: 20px">

      <el-select v-model.number="level" placeholder="请选择">
          <el-option
              :value="1"
              label="普通用户">
          </el-option>
          <el-option
              :value="2"
              label="金牌会员">
          </el-option>
          <el-option
              :value="3"
              label="唐玖集品牌大使">
          </el-option>
        </el-select>
      </div>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <el-button type="primary" @click="updateLevel">确定</el-button>
      </div>

    </el-dialog>

    <el-dialog v-if="lookPromoterVisible" :close-on-click-modal="false" :visible.sync="lookPromoterVisible"
               title="查看推广人"
               width="20%">
      <el-table
          :data="lookPromoterTableData"
          style="width: 100%">
        <el-table-column
            label="手机号">
          <template slot-scope="scope">
            {{ scope.row }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {mixins} from "@/views/CustomizationShop/mixins";
import shopUserModal from "@/views/Shop/component/shopUserModal.vue";

export default {
  name: "CustomizationShop",
  components: {shopUserModal},
  mixins: [mixins],
}
</script>


<style scoped>
.text_content {
  position: absolute;
  bottom: 5px;
  color: white;
  text-align: center;
  width: 100%;
}

.code_wrap {
  width: 100%;
  height: 100%;
  background: #3b3b3b91;
  position: absolute;
  backdrop-filter: blur(4px) saturate(150%);
  left: 0;
  top: 0;
  z-index: 99;
}

.code_content {
  width: 140px;
  height: 140px;
  position: absolute;
  left: 50%;
  top: 40%;
  margin-top: -70px;
  margin-left: -70px;
  border-radius: 8px;
}

::v-deep .el-date-editor .el-range-separator {
  display: inline-block;
  height: 100%;
  /* padding: 0 5px; */
  margin: 0;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  width: 7%;
  color: #303133;
}

.thumbnailUrl {
  position: relative;
}

.thumbnailUrl:hover .wrap {
  display: block;
}

.wrap {
  width: 90%;
  aspect-ratio: 134 / 103;
  padding: 1px;
  box-sizing: border-box;
  background: #040404af;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: space-around;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  aspect-ratio: 134 / 103;
}

.preview {
  //position: absolute;
  //right: 15px;
  //top: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.copyUrl {
  color: #fff !important;
}

.copyUrl:hover {
  color: blue !important;
  text-decoration: blue underline;
}
</style>
