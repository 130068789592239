import service from "@/utils/request"

export function getCardListPageApi(params) {
    return service({
        url: '/wx_user_card/getCardList',
        method: 'get',
        params
    })
}


export function wxUserCardGenerateApi(data) {
    return service({
        url: '/wx_user_card/generate',
        method: 'post',
        data
    })
}


export function wxUserCardUpdateStatusApi(data) {
    return service({
        url: '/wx_user_card/updateStatus',
        method: 'post',
        data
    })
}
