<template>
  <div class="card card-flush h-md-50 mb-xl-10" style="display: flex;justify-content: space-around">
    <!--begin::Header-->
    <div class="card-header pt-3">
      <!--begin::Title-->
      <div class="card-title d-flex flex-column">
        <!--begin::Amount-->
        <span class="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
          <count-up :endVal="recordsTotal"></count-up>
        </span>
        <!--end::Amount-->

        <!--begin::Subtitle-->
        <span class="text-gray-500 pt-1 fw-semibold fs-6">总订单</span>
        <!--end::Subtitle-->
      </div>
      <!--end::Title-->
    </div>
    <!--end::Header-->

    <!--begin::Card body-->
    <div class="d-flex flex-column justify-content-end pe-0">
      <div ref="chartContainer" style="width: 100%;height: 100%"></div>
    </div>
    <!--end::Card body-->
  </div>
</template>
<script>
import echarts from "echarts";
import {getDayOrderCountApi, getOrderSjListApi} from "@/api/orderApi";
import sd from "silly-datetime"
import {mapState} from "vuex";

export default {
  name: 'allOrder',
  computed: {
    ...mapState("user", ["permissionList"])
  },
  data() {
    return {
      dataArr: [],
      recordsTotal: 0,
      weekdays: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
      dateArray: ["2024-01-10", "2024-01-11", "2024-01-12", "2024-01-13", "2024-01-14", "2024-01-15", "2024-01-16"],
      weekdayArrays:[],
      chartOptions: {
        grid: {
          top: '10%',    // 上边距
          bottom: '10%', // 下边距
          left: '10%',   // 左边距
          right: '10%'   // 右边距
        },
        xAxis: {
          show: true, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
          axisTick: {
            show: false // 不显示坐标轴刻度线
          },
          axisLine: {
            show: true, // 不显示坐标轴线
            lineStyle: {
              color: '#c23531', // 横轴线颜色
            },
          },
          axisLabel: {
            show: false, // 不显示坐标轴上的文字
          },
          splitLine: {
            show: false // 不显示网格线
          },
          data: this.weekdayArrays
        },
        yAxis: {
          type: 'value',
          show: false, // 不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
          axisTick: {
            show: false // 不显示坐标轴刻度线
          },
          axisLine: {
            show: false, // 不显示坐标轴线
          },
          axisLabel: {
            show: false, // 不显示坐标轴上的文字
          },
          splitLine: {
            show: false // 不显示网格线
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'transparent', // 将提示框的背景颜色设置为透明
            },
          },
        },
        series: [
          {
            barWidth: "30%",
            barCategoryGap: '50%',
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            itemStyle: {
              // 设置柱状图的圆角
              barBorderRadius: [5, 5, 0, 0]  // 从左上角开始顺时针设置四个角的圆角半径
            }
          }
        ]
      },
    }
  },
  methods: {
    async getDayOrderCount(){
      this.dataArr=[];
      let {data:res}=await getDayOrderCountApi();
      let arr=[]
      res.forEach(e=>{
        arr.push(e.count);
      })
      this.dataArr=arr
    },
    weekdayArray() {
      return this.dateArray.map(dateString => {
        return this.weekdays[new Date(dateString).getDay()];
      });
    },
    getAfterDay() {
      // 获取当前日期
      const currentDate = new Date();
      // 获取前七天的日期
      const sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 6); // 减去 6 天
      // 使用 silly-datetime 格式化日期
      const formatOptions = {
        format: 'YYYY-MM-DD', // 日期格式
      };
      // 获取前七天的日期字符串数组
      const sevenDaysDates = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(sevenDaysAgo);
        date.setDate(sevenDaysAgo.getDate() + i);
        const formattedDate = sd.format(date, formatOptions.format);
        sevenDaysDates.push(formattedDate);
      }
      this.dateArray=sevenDaysDates
    },
    async getOrderCount() {
      let {data} = await getOrderSjListApi({
        pageIndex: 1,
        pageSize: 10,
      })
      this.recordsTotal = data.recordsTotal
    },
    async initChart() {
      let chartContainer = this.$refs.chartContainer;
      await this.getDayOrderCount();
      let myChart = echarts.init(chartContainer);
      this.chartOptions.xAxis.data=this.weekdayArrays;
      this.chartOptions.series[0].data=this.dataArr;
      myChart.setOption(this.chartOptions);
    },
  },
  mounted() {

    this.getOrderCount()
    this.getAfterDay();
    this.weekdayArrays = this.weekdayArray()
    this.initChart();
  },
}
</script>
