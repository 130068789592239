<template>
  <div class="container my-6" style="min-height: 86vh;">
    <div class="row g-5 g-xl-10 mb-xl-10">
      <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
        <all-price/>
        <tools/>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
        <day-price/>
        <all-order/>
      </div>
      <div class="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
        <jumbotron/>
      </div>
      <!--end::Col-->
    </div>
    <div class="row gy-5 g-xl-10">
    <div class="col-6  g-5 g-xl-10 mb-xl-10">
      <MonthPrice class="card"></MonthPrice>
    </div>
    <div class="col-6  g-5 g-xl-10 mb-xl-10">
      <MonthOrder class="card"></MonthOrder>
    </div>
  </div>
  </div>
</template>
<script>
import sd from "silly-datetime";
import salePricEcharts from "./components/salePricEcharts.vue";
import orderEcharts from "./components/orderEcharts.vue";
import allPrice from "@/views/Dashboard/components/allPrice.vue";
import Tools from "@/views/Dashboard/components/tools.vue";
import DayPrice from "@/views/Dashboard/components/dayPrice.vue";
import AllOrder from "@/views/Dashboard/components/allOrder.vue";
import Jumbotron from "@/views/Dashboard/components/jumbotron.vue";
import PlatformRecommendation from "@/views/Dashboard/components/PlatformRecommendation.vue";
import Help from "@/views/Dashboard/components/help.vue";
import MonthPrice from "@/views/Dashboard/components/MonthPrice.vue";
import MonthOrder from "@/views/Dashboard/components/MonthOrder.vue";
import {mapState} from "vuex";

export default {
  name: "Dashboard",
  data() {
    return {

    };
  },
  computed:{
    ...mapState("user",["role"])
  },
  components: {
    MonthOrder,
    MonthPrice,
    Help,
    PlatformRecommendation,
    Jumbotron,
    AllOrder,
    DayPrice,
    Tools,
    salePricEcharts,
    orderEcharts, allPrice
  },
  methods: {
    sdF() {
      let weekStr = ["日", "一", "二", "三", "四", "五", "六"];
      let week = new Date().getDay();
      return sd.format(new Date(), "YYYY年MM月DD号") + "星期" + weekStr[week];
    },
  },
  created() {
  },
};
</script>
<style scoped>
.banner {
  height: 48px;
  line-height: 48px;
  background: #fff;
  padding: 0 18px;
  color: #333;
  font-weight: 700;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dashboard-box {
  background: #fff;
  padding: 10px;
}

.dashboard-box > div.el-card__body {
  padding: none !important;
}

.Del-col {
  padding: 0 !important;
  padding-right: 10px !important;
}

.Del-col1 {
  padding-right: 0 !important;
}
</style>
