<template>
  <div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div
          id="kt_content_container"
          class="container"
          style="
          margin-top: 10px;
          max-width: 100%;
          flex-direction: row !important;
          display: flex;
        "
      >
        <!-- 搜索开始 -->
        <div
            class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5"
        >
          <form action="#" id="own_goods_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem">
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >商品标题</label
                  >
                  <div class="position-relative">
                    <span
                        class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                      >
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                          ></path>
                          <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <input
                        id="title"
                        type="text"
                        class="form-control form-control-solid ps-10"
                        name="search"
                        v-model="params.title"
                        value=""
                        placeholder="请输入需要查询的商品标题名称"
                    />
                  </div>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >一级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="params.categoryId1"
                      data-placeholder="全部"
                      @change="findCategory(1)"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryOne"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >二级分类</label
                  >
                  <select
                      class="form-select form-select-solid"
                      v-model="params.categoryId2"
                      @change="findCategory(2)"
                      data-placeholder="全部"
                  >
                    <option value="">全部</option>
                    <option
                        v-for="(item, index) in categoryTwo"
                        :key="index"
                        :value="item.categoryId"
                    >
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >全国商品池</label
                  >
                  <select
                      v-model="params.isQuan"
                      class="form-select form-select-solid"
                  >
                    <option value="">全部</option>
                    <option value="1">加入全国商品池</option>
                    <option value="2">不加入全国商品池</option>
                  </select>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">商品来源</label>
                  <select class="form-select form-select-solid" v-model="params.goodsStaffId" data-placeholder="全部">
                    <option value="">全部</option>
                    <option v-for="(item, index) in staffArr" :key="index" :value="item.staffId">{{ item.backup }}</option>
                  </select>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">是否支持7天无理由</label>
                  <select class="form-select form-select-solid" v-model="params.sevenNRR" data-placeholder="全部">
                    <option value="">全部</option>
                    <option value="1">支持</option>
                    <option value="0">不支持</option>
                  </select>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">发货时效</label>
                  <el-checkbox-group v-model="deliveryTime">
                    <el-checkbox label="24小时内发货"></el-checkbox>
                    <el-checkbox label="48小时内发货"></el-checkbox>
                    <el-checkbox label="48小时内发货(周末、节假日除外)"></el-checkbox>
                    <el-checkbox label="72小时内发货"></el-checkbox>
                    <el-checkbox label="其他"></el-checkbox>
                  </el-checkbox-group>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >库存区间</label
                  >
                  <div class="row">
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                          type="text"
                          id="minStock"
                          name="minStock"
                          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          v-model="params.minStock"
                          oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          placeholder="最小值"
                          value=""
                      />
                      <div
                          class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>

                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                          type="text"
                          id="maxStock"
                          name="maxStock"
                          class="form-control form-control-lg form-control-solid"
                          v-model="params.maxStock"
                          oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          placeholder="最大值"
                          value=""
                      />
                      <div
                          class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark"
                  >利润区间</label
                  >
                  <div class="row">
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                          type="text"
                          id="minRightRate"
                          name="minRightRate"
                          class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          v-model="params.minRightRate"
                          oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          placeholder="最小值"
                          value=""
                      />
                      <div
                          class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                      <input
                          type="text"
                          id="maxRightRate"
                          name="maxRightRate"
                          class="form-control form-control-lg form-control-solid"
                          v-model="params.maxRightRate"
                          oninput="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          placeholder="最大值"
                          value=""
                      />
                      <div
                          class="fv-plugins-message-container invalid-feedback"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center justify-content-end">
                  <a
                      @click="shaixuan"
                      href="javascript:void(0);"
                      class="btn btn-primary"
                  >筛选</a
                  >&nbsp;&nbsp;&nbsp;
                  <!--btn-active-light-primary 点击变色-->
                  <a
                      @click="resetQuery();"
                      href="javascript:void(0);"
                      class="btn text-hover-primary bg-hover-light  btn-color-gray-400 me-3"
                  >重置</a
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div
            class="card mb-5 mb-xl-8"
            style="margin-left: 3px; width: 100%"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            v-loading="loading"
        >
          <div class="card-body py-3" style="padding-left: 15px">
            <div class="table-responsive">
              <table
                  class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                  id="kt_ecommerce_products_table"
              >
                <thead>
                <tr
                    class="text-start text-gray-500 fs-7 text-uppercase gs-0"
                >
                  <th
                      class="w-10px pe-2 sorting_disabled"
                      rowspan="1"
                      colspan="1"
                      aria-label=""
                      style="width: 2%"
                  >
                    <div
                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          data-kt-check="true"
                          data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                          value="TRUE"
                          v-model="checkboxInput"
                          @change="checkboxInputEvent"
                      />
                    </div>
                  </th>
                  <th
                      class="min-w-170px"
                      tabindex="0"
                      aria-controls="kt_ecommerce_products_table"
                      rowspan="1"
                      colspan="1"
                      aria-label="Product: activate to sort column ascending"
                      style="width: 17%"
                  >
                    商品信息
                  </th>
                  <th
                      class="text-center"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="分类"
                      style="width: 7%"
                  >
                    分类
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="市场价"
                      style="width: 7%"
                  >
                    市场价
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="分销价"
                      style="width: 7%"
                  >
                    分销价
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="全国商品池"
                      style="width: 7%"
                  >
                    商品池
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="全国分销价(加价)"
                      style="width: 7%"
                  >
                    全国分销价
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="利润率"
                      style="width: 6%"
                  >
                    利润率
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="销售价"
                      style="width: 6%"
                  >
                    销售价
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="销量"
                      style="width: 4%"
                  >
                    销量
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="运费模板"
                      style="width: 7%"
                  >
                    运费模板
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="库存"
                      style="width: 4%"
                  >
                    库存
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="商品来源"
                      style="width: 6%"
                  >
                    商品来源
                  </th>
                  <th
                      class="text-center min-w-55px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="状态"
                      style="width: 6%"
                  >
                    状态
                  </th>
                  <th
                      class="text-center min-w-70px"
                      tabindex="0"
                      rowspan="1"
                      colspan="1"
                      aria-label="更新时间"
                      style="width: 10%"
                  >
                    更新时间
                  </th>
                </tr>
                </thead>
                <tbody class="fw-semibold text-gray-600">
                <tr
                    v-for="(item, index) in rows"
                    :class="{ old: index % 2 == 0, even: index % 2 != 0 }"
                    :key="index"
                >
                  <!-- 复选按钮 -->
                  <td>
                    <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                    >
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          @click="addIdArr(item.id)"
                          :checked="idArr.includes(item.id)"
                      />
                    </div>
                  </td>
                  <!-- 商品信息 -->
                  <td style="padding-right: 0">
                    <div
                        class="d-flex align-items-center"
                        @click="
                          openGoodInfo(
                            item.isJD == 1 ? item.skuId : item.id,
                            item.isJD
                          )
                        "
                    >
                      <a href="javascript:void(0)" class="symbol symbol-50px">
                        <img
                            class="symbol-label lozad"
                            v-if="item.picUrl.indexOf('http') == 0"
                            :src="`${item.picUrl}`"
                        />
                        <img
                            class="symbol-label lozad"
                            v-else
                            :src="`https://www.3jzc.cn${item.picUrl}`"
                        />
                      </a>
                      <div
                          style="
                            max-width: 170px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            margin-left: 10px;
                          "
                      >
                        <a
                            href="javascript:void(0)"
                            :title="item.title"
                            class="text-gray-800 text-hover-primary fs-5"
                            data-kt-ecommerce-product-filter="product_name"
                        >{{ item.title }}</a
                        >
                      </div>
                    </div>
                  </td>
                  <!-- 分类 -->
                  <td class="text-center pe-0">
                    <div
                        style="font-size: 90%"
                        v-html="item.categoryName"
                    ></div>
                  </td>
                  <!-- 市场价 -->
                  <td
                      class="text-center pe-0 text-black"
                      @click="openEditPriceDialog(item)"
                  >
                      <span
                          style="
                          font-size: 90%;
                        "
                      >
                        {{ item.price | format }}
                      </span>
                  </td>
                  <!-- 分销价 -->
                  <td
                      class="text-center pe-0 text-black"
                  >
                      <span
                          style="
                          font-size: 90%;
                        "
                      >
                        {{ item.supplPrice | format }}
                      </span>
                  </td>
                  <!-- 全国商品池 -->
                  <td class="text-center pe-0">
                      <span
                          v-if="item.isQuan == 1 && item.status==0"
                          class=" text-warning"
                          style="font-size: 90%"
                      >待审核</span
                      >
                    <span
                        v-if="item.isQuan == 1 && item.status==1"
                        class=" text-success"
                        style="font-size: 90%"
                    >审核通过</span
                    >
                    <span
                        v-if="item.isQuan == 1 && item.status==2"
                        class=" text-danger"
                        style="font-size: 90%"
                    >审核未通过</span
                    >
                    <span
                        v-if="item.isQuan == 2 || (item.status==-1)"
                        class="text-danger"
                        style="font-size: 90%"
                    >不加入</span
                    >
                  </td>
                  <!-- 全国分销价 -->
                  <td>
                    <div
                        class="text-center pe-0 text-black min-w-75px"
                        v-if="item.quanSupplyPrice != '-'"
                    >
                        <span
                            style="
                            font-size: 90%;
                          "
                            v-html="item.quanSupplyPrice"
                        ></span>
                    </div>
                    <div class="text-center" v-else>-</div>
                  </td>
                  <!-- 利润率 -->
                  <td class="text-center pe-0">
                      <span
                          style="font-size: 90%"
                          v-html="item.rightRate"
                      ></span>
                  </td>
                  <!-- 销售价 -->
                  <td class="text-center pe-0">
                      <span
                          class=" text-black"
                          style="
                          font-size: 90%;
                        "
                      >
                        {{ item.salePrice | format }}</span
                      >
                  </td>
                  <!-- 销量 -->
                  <td class="text-center pe-0">
                      <span style="font-size: 90%">{{
                          item.saleNum
                        }}</span>
                  </td>
                  <!-- 运费模板 -->
                  <td class="text-center pe-0">
                      <span
                          style="
                          word-break: break-all;
                          overflow: hidden;
                          font-size: 90%;
                          display: -webkit-box;
                          -webkit-line-clamp: 2;
                          -webkit-box-orient: vertical;
                        "
                          :title="item.freightId"
                      >
                        {{ item.freightId }}
                      </span>
                  </td>
                  <!-- 库存 -->
                  <td class="text-center pe-0">
                      <span
                          class="text-black"
                          style="
                          font-size: 90%;
                        "
                      >{{ item.stock || 0}}</span
                      >
                  </td>
                  <!-- 商品来源 -->
                  <td class="text-center pe-0">
                      <span style="font-size: 90%">{{
                          item.type
                        }}</span>
                  </td>
                  <!-- 状态 -->
                  <td class="text-center pe-0">
                      <span
                          class="text-center"
                          v-if="item.isShow.indexOf('已上架') >= 0"
                      >
                        <span
                            class="badge badge-light-success fs-7"
                            style="
                            display: block;
                            text-align: center;
                            margin: 0 auto;
                          "
                            v-html="sArr(item.isShow)[0]"
                        ></span>
                        <span class="text-muted text-muted d-block fs-7"
                              style="display:block;text-align:center;" v-html="sArr(item.isShow)[1]"></span>
                      </span>
                    <span
                        class="badge badge-light-danger fs-7"
                        v-else
                        style="display: block; text-align: center; width: 80px"
                        v-html="item.isShow"
                    ></span>
                  </td>
                  <!-- 更新时间 -->
                  <td class="text-center pe-0">
                    <span>{{ item.crTime }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :total-items="totalCount"
                  @page-change="pageChange"
                  ref="Pagination"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  弹窗 -->
    <!-- 商品详情 -->
    <ModalDialog title="商品详情" id="goodInfo" ref="goodInfo" width="1000px">
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </ModalDialog>
  </div>
</template>
<script>
// 导入数据api
import {
  unSelectedGoodsApi,
  getSjGoodsListApi,
  getSjGoodsListCountApi,
  getSjGoodsListBySpuIdApi,
  getStaffListApi
} from "@/api/goodsManageApi";
import GoodInfoComponent from "@/components/GoodInfoComponent";
// 页码指定跳转
import Pagination from "@/components/Pagination/index.vue";
import {getCategoryList} from "@/api/categoryApi";

export default {
  components: {
    Pagination,
    GoodInfoComponent,
  },
  props: {
    groupId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      staffArr: [],
      categoryOne: [],
      categoryTwo: [],
      idArr: [],
      checkboxInput: false,
      rows: [],
      loading: false,
      params: {
        current: 1,
        size: 10,
        phone: "",
        spuId: "",
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: "1",
        isTwoShow: 1,
        stock: "",
        isQuan: "",
        goodsGroupId: this.groupId,
        goodsStaffId: "",
        minStock: "",
        maxStock: "",
        sevenNRR: "",
        maxRightRate: "",
        minRightRate: "",
        deliveryTime: ""
      },
      deliveryTime: [],
      poolSupplPrice: "",
      totalCount: 999,
      first: true,
      role: []
    };
  },
  computed: {
  },
  // 方法
  methods: {
    reloadIdArr() {
      this.idArr = [];
      this.$emit("setArrId", this.idArr)
    },
    setArrId() {
      this.$emit("setArrId", this.idArr)
    },
    sArr(text) {
      return text.split("<br>")
    },
    //重置
    resetQuery() {
      this.params = {
        current: 1,
        size: 10,
        phone: "",
        spuId: "",
        title: "",
        categoryId1: "",
        categoryId2: "",
        isShow: this.params.isShow,
        goodsGroupId: this.groupId,
        isTwoShow: 1,
        stock: "",
        isQuan: "",
        goodsStaffId: "",
        minStock: "",
        maxStock: "",
        sevenNRR: "",
        maxRightRate: "",
        minRightRate: "",
        deliveryTime: ""
      }
      this.first = true
      this.getSjGoodsList()
    },
    checkboxInputEvent() {
      if (this.checkboxInput) {
        this.rows.forEach((e) => {
          if (!this.idArr.includes(e.id)) {
            this.idArr.push(e.id);
          }
        });
      } else {
        this.rows.forEach((e) => {
          let index = this.idArr.indexOf(e.id);
          if (index !== -1) {
            this.idArr.splice(index, 1);
          }
        });
      }
      this.setArrId()
    },
    async addIdArr(id) {
      if (this.idArr.includes(id)) {
        let index = this.idArr.indexOf(id);
        if (index !== -1) {
          this.idArr.splice(index, 1);
        }
      } else {
        this.idArr.push(id);
      }
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
      this.setArrId()

    },
    async checkGoods(spuId) {
      let {data} = await getSjGoodsListBySpuIdApi({
        spuId,
      });
      this.spuIdList = data.rows;
      this.dialogTableVisible = true;
    },

    // 打开弹窗并赋值
    openEditPriceDialog(item) {
      if (item) {
        this.$refs.editPriceModalDialog.open();
        this.editObj.ids = Array.from([item.id]);
        this.editObj.price = item.price;
      } else {
        this.$refs.editPriceModalDialog.close();
      }
    },

    openGoodInfo(id, isJd) {
      this.$refs.goodInfo.open();
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
    },
    // 传递id
    editEvent(id) {
      this.$emit("editEvent", id);
    },
    // 分页查询
    async getSjGoodsList() {
      if (this.deliveryTime.length > 0) {
        this.params.deliveryTime = this.deliveryTime.join(',');
      } else {
        this.params.deliveryTime = "";
      }
      this.checkboxInput = false;
      this.loading = true;
      let {data} = await unSelectedGoodsApi(this.params);
      this.loading = false;
      this.rows = data.rows;
      this.totalCount = data.total
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.id)) {
          a = false;
        }
      });
      this.checkboxInput = a;
      this.setArrId()
    },
    // 分页
    pageChange(currentPage, pageSize) {
      this.params.current = currentPage;
      this.params.size = pageSize;
      this.getSjGoodsList();
    },
    // 求和
    async getSjGoodsPageCount() {
      let {data} = await getSjGoodsListCountApi(this.params);
      this.totalCount = data;
    },
    toggleDropdown(index) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(`dropdown-${index}`);
        new KTMenu(dropdown);
      });
    },
    // 更改上架下架
    changIsShow(index) {
      this.idArr = []
      this.first = true;
      this.params.isShow = index;
      this.params.isTwoShow = 1;
      this.getSjGoodsList();
    },
    // 筛选
    shaixuan() {
      this.idArr = []
      this.first = true;
      if (this.params.current != 1) {
        this.$refs.Pagination.goToPage(1);
      } else {
        this.getSjGoodsList();
      }
    },
    // 查询分类
    async findCategory(index) {
      this.first = true;
      // this.params.isShow = index;
      if (!this.params.categoryId1){
        this.params.categoryId1 = 0;
      }
      if (index == 1) {
        if (!this.params.categoryId1){
          this.params.categoryId1 = 0;
          this.params.categoryId2 = "";
          this.categoryTwo = [];
        }
        let {data} = await getCategoryList({
          parentId: this.params.categoryId1,
        });
        if (this.params.categoryId1 !== 0) {
          this.categoryTwo = data.list;
        }
      }
      if (this.params.categoryId1 === 0){
        this.params.categoryId1 = "";
      }
      this.getSjGoodsList();
    },
    //初始化分类
    async initCategory() {
      let {data} = await getCategoryList({
        parentId: 0,
      });
      this.categoryOne = data.list;
    },
    // 获取员工列表
    async getStaffList() {
      let {data} = await getStaffListApi();
      this.staffArr = data;
    },
    // 获取角色权限
    getLoginRole() {
      this.role = localStorage.getItem('role')
    }
  },
  // 父类index使用
  createGoods() {
  },
  // 初始化这个方法
  created() {
    this.getSjGoodsList();
    this.initCategory();
    this.getStaffList();
  },
  updated() {
    // 当数据更新后，可以在此处手动初始化下拉菜单插件
    this.rows.forEach((_, index) => {
      this.toggleDropdown(index);
    });
  },
};
</script>
<!--组件样式-->
<style scoped>
.borderHover:hover {
  border-color: rgb(0, 158, 247) !important;
}

/* 修改滚动条样式 */
.table-responsive::-webkit-scrollbar {
  width: 20px; /* 设置滚动条宽度 */
  height: 15px;
  background-color: #F5F5F5; /* 设置滚动条背景颜色 */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滚动条滑块颜色 */
  border-radius: 5px; /* 设置滚动条滑块圆角 */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滚动条滑块在鼠标悬停时的颜色 */
}
</style>