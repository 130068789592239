import QRCode from "qrcode";
import Clipboard from "clipboard";
import {
    getBindWxUserTelApi,
    getWxUserCountApi,
    getWxUserPageApi,
    getWxUserScoreApi,
    updateUserLevelApi,
    updateUserScoreApi
} from "@/api/WxUserApi";
import {getCardListPageApi, wxUserCardGenerateApi, wxUserCardUpdateStatusApi} from "@/api/WxUserCardApi";
import sd from "silly-datetime";

export const mixins = {
    data() {
        return {
            rows: [
                {
                    "id": 366,
                    "staffId": 1126,
                    "name": "唐玖集分销商城",
                    "templateId": 3,
                    "bannerUrl": "",
                    "logoUrl": null,
                    "customerTel": "18157160780",
                    "wxCustomer": "",
                    "status": 2,
                    "crTime": "2024-05-13 00:00:00",
                    "endTime": "2024-05-09",
                    "publication": 2,
                    "shopType": 1,
                    "isCash": 1,
                    "showScore": 1,
                    "scoreRatio": 1,
                    "unit": "积分",
                    "provinceId": null,
                    "cityId": null,
                    "countyId": null,
                    "indexJson": "[{\"name\":\"header-backgroundAndSearch\",\"url\":\"https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/shopTemplate/moRenHeadH5.png\",\"iskefu\":0,\"isCar\":0},{\"name\":\"jinGang\",\"num\":5,\"categoryLevel\":2},{\"name\":\"active-banner\",\"num\":5,\"categoryLevel\":1},{\"name\":\"allCategoryArea\",\"categoryLevel\":0},{\"name\":\"waterfallArea\"}]",
                    "tplCode": "259853",
                    "isSystem": false,
                    "afterSalesTel": "18157160780",
                    "comparingPrices": true,
                    "pageUrl": "html/index.html",
                    "picUrl": null,
                    "thumbnailUrl": "https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/shopTemplate/mubanH5Thumbnail.png",
                    "goodsNum": 1670,
                    "userNum": 0,
                    "statusStr": "已停用",
                    "right": null,
                    "chooseStatus": null,
                    "averagePrice": null,
                    "orderNum": 0
                }
            ],
            code_show: false,
            code_id: "",
            qrCodeUrl: "",
            count: 0,
            wxShopUserVisible: false,
            tableData: [],
            wxUserParams: {
                current: 1,
                size: 10,
                tel: "",
                cardId: "",
                cardPassword: "",
                status: "",
                level: ""
            },
            tableDataTotal: 0,
            wxShopCardVisible: false,
            tableCardData: [],
            wxUserCardParams: {
                current: 1,
                size: 10,
                tel: ""
            },
            tableDataCardTotal: 0,
            wxShopCarGeneratedVisible: false,
            cardRuleForm: {
                num: "",
                cardNoPrefix: "",
                score: "",
                length: "",
                itemRule: "",
                endTime: ""
            },
            options: [{
                value: '1',
                label: '待激活'
            }, {
                value: '2',
                label: '待使用'
            }, {
                value: '3',
                label: '已使用'
            }, {
                value: '4',
                label: '作废'
            }],
            wxShopCarBatchVisible: false,
            formBatch: {
                startCardId: "",
                endCardId: "",
                status: ""
            },
            wxShopUserScoreVisible: false,
            wxUserScoreParams: {
                current: 1,
                size: 10,
                userId: ""
            },
            wxUserScoreTableData: [],
            wxShopUserScoreTotal: 0,
            editLevelVisible: false,
            level: "",
            levelUserId: "",
            lookPromoterVisible: false,
            lookPromoterTableData: [],

        }
    },
    methods: {
        openQrcode(id, shopId) {
            this.creatQrCode("https://baijiuji.h5.zhelidao.com")
            this.code_id = id
            this.code_show = !this.code_show
        },
        creatQrCode(pageUrl) {
            //QRCode:二维码生成插件
            QRCode.toDataURL(pageUrl, {margin: 1}).then(res => {
                this.qrCodeUrl = res
            })
        },
        copyUrl() {
            let clipboard = new Clipboard(".copyUrl");
            clipboard.on("success", e => {
                // 释放内存
                this.$message({
                    message: "复制成功",
                    type: "success"
                });
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                // 不支持复制
                this.$message({
                    message: "该浏览器不支持自动复制",
                    type: "warning"
                });
                // 释放内存
                clipboard.destroy();
            });
        },
        async getUserCount() {
            let {data} = await getWxUserCountApi()
            this.count = data
        },
        async getWxShopUserList() {
            let {data} = await getWxUserPageApi(this.wxUserParams)
            this.tableData = data.rows
            this.tableDataTotal = data.total
        },
        openWxUserList() {
            this.getWxShopUserList()
            this.wxShopUserVisible = true
        },
        handleEdit(row) {
            this.$prompt('请输入要修改的积分', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^[0-9]*$/,
                inputErrorMessage: '积分格式不正确',
                inputValue: row.score
            }).then(({value}) => {
                this.updateUserScore(row.id, value)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        checkScore(row) {
            this.wxUserScoreTableData = []
            this.wxUserScoreParams.current = 1
            this.wxUserScoreParams.size = 10
            this.wxUserScoreParams.userId = row.id
            this.getWxUserScore();
            this.wxShopUserScoreVisible = true
        },
        async getWxUserScore() {
            let {data} = await getWxUserScoreApi(this.wxUserScoreParams)
            this.wxUserScoreTableData = data.rows
            this.wxShopUserScoreTotal = data.total
        },
        editLevel(row) {
            this.level = row.level
            this.levelUserId = row.id
            if (row.withdrawalAmount != 0) {
                this.$message.error("该用户未提现，不能修改等级")
                return
            }
            this.editLevelVisible = true
        },
        async updateLevel() {
            await updateUserLevelApi({userId: this.levelUserId, level: this.level})
            this.$message.success("修改成功")
            this.editLevelVisible = false
            this.getWxShopUserList()
        },
        async lookPromoter(row) {
            let {data} = await getBindWxUserTelApi({userId: row.id})
            this.lookPromoterTableData = data
            this.lookPromoterVisible = true
        },
        async updateUserScore(userId, score) {
            await updateUserScoreApi({userId, score})
            this.$message.success("修改成功")
            this.getWxShopUserList()
        },
        handleSizeChange(val) {
            this.wxUserParams.size = val
            this.getWxShopUserList()
        },
        handleCurrentChange(val) {
            this.wxUserParams.current = val
            this.getWxShopUserList()
        },
        handleSizeChangeCard(val) {
            this.wxUserCardParams.size = val
            this.getWxShopCardList()
        },
        handleCurrentChangeCard(val) {
            this.wxUserCardParams.current = val
            this.getWxShopCardList()
        },
        handleSizeChangeScore(val) {
            this.wxUserScoreParams.size = val
            this.getWxUserScore()
        },
        handleCurrentChangeScore(val) {
            this.wxUserScoreParams.current = val
            this.getWxUserScore()
        },
        resetParams() {
            this.wxUserParams = {
                current: 1,
                size: 10,
                tel: ""
            }
            this.getWxShopUserList()
        },
        openCard() {
            this.wxShopCardVisible = true
            this.getWxShopCardList()
        },
        async getWxShopCardList() {
            let {data} = await getCardListPageApi(this.wxUserCardParams)
            this.tableCardData = data.rows
            this.tableDataCardTotal = data.total
        },
        openGenerated() {
            this.resetGeneratedForm()
            this.wxShopCarGeneratedVisible = true;
        },
        async submitGeneratedForm() {

            let obj = JSON.parse(JSON.stringify(this.cardRuleForm))
            let time = obj.endTime
            obj.endTime = sd.format(+new Date(time))
            await wxUserCardGenerateApi(obj)
            this.$message.success("生成成功")
            this.wxShopCarGeneratedVisible = false
            setTimeout(() => {
                this.getWxShopCardList()
            }, 1000)
        },
        resetGeneratedForm() {
            this.cardRuleForm = {
                num: "",
                cardNoPrefix: "",
                score: "",
                length: "",
                itemRule: "",
                endTime: ""
            }
        },
        async updateCardStatusByOne(row, status) {
            await wxUserCardUpdateStatusApi({id: row.id, status: status})
            this.$message.success("修改成功")
            this.getWxShopCardList()
        },
        openBatchUpdate() {
            this.wxShopCarBatchVisible = true
        },
        resetWxShopCardList() {
            this.wxUserCardParams = {
                current: 1,
                size: 10,
                tel: ""
            }
            this.getWxShopCardList()
        },
        async updateCardStatusByBatch() {
            await wxUserCardUpdateStatusApi(this.formBatch)
            this.$message.success("修改成功")
            this.wxShopCarBatchVisible = false
            this.getWxShopCardList()
        }


    },
    created() {
        this.getUserCount()
    }
}
