import service from "@/utils/request"

// 提审审核列表
export function getWithdrawalPageListApi(params) {
    return service({
        url: '/baijiuji_withdrawal_application/getAllByPage',
        method: 'get',
        params
    })
}

// 提现审核 通过或拒绝
export function updateWithdrawalPassOrRejectApi(params) {
    return service({
        url: '/baijiuji_withdrawal_application/passOrReject',
        method: 'get',
        params
    })
}
